/* 탑 패널 전체 스타일 */
.topmenu {
  width: 100%; /* 너비를 화면 전체로 확장 */
  height: 81px; /* 높이를 적절한 크기로 설정 */
  background-color: #2d5d4d;
  display: flex;
  flex-direction: row; /* 메뉴 아이템들을 가로로 배치 */
  align-items: center; /* 메뉴 아이템을 수직 중앙 정렬 */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 아래쪽 그림자 효과 */
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1000; /* 다른 요소 위에 위치 */
  margin-left: 0.5px;
}

/* 패널 상단 로고와 타이틀 */
.top-header {
  text-align: left; /* 왼쪽 정렬 */
  margin-left: 50px; /* 좌측 마진 추가 */
  white-space: nowrap;
}

.top-title {
  color: #ffffff;
  font-size: 38px;
  font-family: "AppleSDGothicNeoH";
}

.top-title a {
  text-decoration: none; /* 링크의 밑줄 제거 */
  color: inherit; /* 부모 요소의 색상을 그대로 상속 */
}

/* 메뉴 아이템 스타일 */
.menu {
  display: flex;
  flex-direction: row; /* 메뉴를 가로로 배치 */
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-right : 200px;
  gap : 38px;
}

.menu-item {
  padding: 10px 0px;
  display: flex;
  align-items: center;
  color: #ffffff;
  font-family: "AppleSDGothicNeoM";
  font-size: 18px; /* 글씨 크기를 조정 */
  text-decoration: none;
  transition: color 0.3s ease;
  white-space: nowrap; /* 텍스트 줄바꿈 방지 */
  position: relative;
}

.menu-item:hover {
  color: rgba(255, 255, 255, 0.2); /* 글자색 투명도 조정 */
}

/* 서브 메뉴 스타일 */
.submenu {
  display: none; /* 기본적으로 숨김 */
  position: absolute; /* 상위 메뉴에 상대적으로 위치 */
  top: 100%; /* 부모 요소 바로 아래 */
  left: 0px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 그림자 효과 */
  border-radius: 4px;
  z-index: 1000; /* 다른 요소 위에 위치 */
  min-width: 170px; /* 서브 메뉴 너비 */
  padding: 0 0;
}

.submenu-drop {
  left : auto;
  right: 0 !important;
}

/* 서브 메뉴 항목 스타일 */
.submenu-item {
  display: flex;
  align-items: center;
  padding: 8px 15px;
  min-height: 35px;
  color: #000000;
  font-size: 13px;
  text-decoration: none;
  font-family: "AppleSDGothicNeoM";
  transition: background-color 0.3s ease;
}

.submenu-item:hover {
  background-color: #3E7C5C; /* 호버 시 색상 변경 */
  color: #FFFFFF;
}

/* 부모 메뉴 아이템 호버 시 서브 메뉴 표시 */
.menu-item:hover .submenu {
  display: block;
}

.hamburger-menu {
  display: none;
}

.dropdown-item:hover .submenu-children {
  display: block;
}

.submenu-children {
  display: none;
  position: absolute;
  top: 0;
  left: 100%;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  z-index: 1000;
  min-width: 170px; /* 기본 드롭다운과 동일한 너비 설정 */
  padding: 0;
}

.with-arrow {
  display: flex;
  align-items: center;
  justify-content: space-between; /* 텍스트와 아이콘 사이 공간 분배 */
  position: relative;
}

/* 화살표 아이콘 스타일 */
.arrow-icon {
  font-size: 15px;
  margin-left: auto; /* 텍스트와 아이콘 사이 간격을 위해 왼쪽 여백 */
  transition: color 0.3s ease;
}

.menu-icon {
  position : fixed;
  top: 15px;
  right: 0px;
  font-size: 28px;
  color: #ffffff;
  margin-left: auto; /* 이전 요소와 최대한의 간격을 만들어서 오른쪽 끝으로 밀어냄 */
  margin-right : 10px;
  z-index: 1100; /* 사이드바보다 위에 위치 */
  cursor: pointer;
}

.sidebar-panel {
  position: fixed;
  top: 0;
  right: -300px; /* 처음에는 화면 밖에 위치 */
  width: 255px;
  height: 100%;
  background-color: #ffffff;
  transition: right 0.3s ease;
  z-index: 1200;
}

.sidebar-panel.open {
  right: 0; /* 패널 열릴 때 화면 안으로 들어옴 */
}

.sidebar-header {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  padding: 20px 15px;
  background-color: #000;
  color: #fff;
  border: 1px solid #333;
}

.sidebar-header-name {
  display: flex;
  flex-direction: column; /* 이름과 이메일을 세로로 정렬 */
  margin-top: 0px;
  margin-left: 2px;
}

.sidebar-title {
  font-size: 12px;
  font-family: "AppleSDGothicNeoM";
  margin: 0;
}

.sidebar-subtitle {
  font-size: 10px;
  color: #aaa; /* 이메일 색상 회색 */
  margin: 4px 0 0; /* 위쪽 여백 */
}

.sidebar-menu {
  font-size: 18px; /* 아이콘 크기 */
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  top: 55px; /* 아이콘 아래에 위치하도록 설정 */
  right: 5px; /* 오른쪽 정렬 */
  background-color: #fff; /* 배경색 */
  border: 1px solid #ddd;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  z-index: 1400;
  width: 120px; /* 메뉴 너비 */
}

.dropdown-menu-item {
  padding: 10px;
  font-size: 11px;
  margin-left: 10px;
  color: #333;
  cursor: pointer;
}

.close-icon-outside {
  position: fixed;
  top: 15px;
  right: 265px; /* 사이드 패널의 너비를 고려하여 바로 왼쪽에 배치 */
  font-size: 25px;
  color: #ffffff;
  cursor: pointer;
  z-index: 1300; /* 오버레이 및 사이드바보다 위에 위치 */
}

.sidebar-content {
  display: flex;
  flex-direction: column;
  padding: 0;
}

.sidebar-item {
  display: flex;
  justify-content: space-between;
  padding: 12px 17px;
  color: #333333;
  text-decoration: none;
  font-size: 12px;
  font-family: "AppleSDGothicNeoM";
  cursor: pointer;
  
}

.sidebar-line {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.sidebar-item.active {
  background-color: #efefef; /* 선택된 메뉴 배경색 */
}

.sidebar-item-dropdown {
  padding: 5px 25px;
  font-size: 12px; /* 글자 크기 조정 */
  font-family: "AppleSDGothicNeoM";
  color: #555;
  border-bottom: none;
}

.sidebar-item-dropdown2 {
  padding: 5px 25px;
  padding-bottom: 15px;
  font-size: 12px; /* 글자 크기 조정 */
  font-family: "AppleSDGothicNeoM";
  color: #555;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 어둡게 처리 */
  z-index: 900; /* 사이드바보다 낮은 값 설정 */
}

.logout-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 20px; /* 오른쪽 패딩 조정 */
  gap: 10px; /* 요소들 사이의 간격 조정 */
  white-space: nowrap;
}

.logout-container p {
  margin : 0;
  color: #ffffff;
  font-family: "AppleSDGothicNeoM";
  font-size: 18px;
}

.user-name {
  font-size: 18px; /* 예: 좀 더 크게 */
}

.user-suffix {
  font-size: 14px; /* 예: 조금 작게 */
}

.logout-button {
  background: none;
  border: none;
  padding: 5px 10px; /* 버튼 내부 패딩 */
  cursor: pointer;
  color: #FFFFFF;
  font-family: "AppleSDGothicNeoM";
  font-size: 18px;
  transition: color 0.3s ease; /* 부드러운 색상 전환 */
}

.logout-button:hover {
  color: rgba(255, 255, 255, 0.2); 
}

@media (max-width : 990px) {
  .topmenu {
    height: 58px;
  }
  
  .top-header {
    margin-left: 25px;
  }

  .top-title {
    font-size: 20px;
  }

  .user-name {
    font-size: 20px;
  }

  .user-suffix {
    font-size: 16px;
  }

  .logout-button {
    font-size: 20px;
  }
}

@media ((min-width: 990px) and (max-width : 1180px)) {
  .topmenu {
    height: 70px;
  }
  
  .top-header {
    margin-left: 15px;
  }

  .top-title {
    font-size: 34px;
  }

  .menu {
    margin-right: 280px;
    gap: 30px;
  }
  
  .menu-item {
    font-size: 15px;
  }

  .user-name {
    font-size: 15px;
  }

  .user-suffix {
    font-size: 11px;
  }

  .logout-button {
    font-size: 15px;
  }
}