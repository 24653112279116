.download {
  display: flex;
  margin-left : auto;
  margin-right : auto;
  max-width: 70vw;
  min-width: 70vw;
  padding: 20px;
  flex-direction: column;
  min-height: 100vh;
  box-sizing : border-box;
}

.search-options {
  position: relative;
  display: flex;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-top : 20px;
  padding-left: 10px;
  height: 40px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.toggle-button {
  display: flex;
  background-color: #ffffff;
  border: none;
  border-radius: 5px;
  padding: 5px 20px;
  font-size: 16px;
  cursor: pointer;
  align-items: center;
}

.options-container {
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.option-group {
  margin-bottom: 20px;
}

.option-group h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.option-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.option-button {
  background-color: #f5f5f5;
  border: 1px solid #cccccc;
  border-radius: 20px;
  padding: 5px 15px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.option-button:hover {
  background-color: #e6e6e6;
}

.option-button.selected {
  background-color: #007bff;
  color: #ffffff;
}

.options-footer {
  display: flex;
  justify-content: flex-end;
}

.search-button{
  background-color: #ffffff;
  border: 1px solid #007bff;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 14px;
  color: #007bff;
  cursor: pointer;
  margin-right: 10px;
}

.search-button:hover{
  background-color: #007bff;
  color: #ffffff;
}

.reset-button {
  background-color: #ffffff;
  border: 1px solid #007bff;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 14px;
  color: #007bff;
  cursor: pointer;
}

.reset-button:hover {
  background-color: #007bff;
  color: #ffffff;
}

.search-result {
  margin-top: 20px; /* options-container와의 간격 */
  background-color: #ffffff;
  border: 1px solid #cccccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 10px;
  overflow-x: auto;
}

.result-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 16px;
  text-align: center;
  font-family: "AppleSDGothicNeoM";
}

.result-table th,
.result-table td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px;
  border: 1px solid #dddddd;
}

.result-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.result-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.loading-icon {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* 페이지네이션 스타일 */
.pagination {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.page-button {
  margin: 0 5px;
  padding: 8px 12px;
  border: 1px solid #007bff;
  background-color: #ffffff;
  color: #007bff;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
}

.page-button:hover {
  background-color: #007bff;
  color: #ffffff;
}

.page-button.active {
  background-color: #007bff;
  color: #ffffff;
  font-weight: bold;
}

.download-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 1px;
  transition: transform 0.2s;
}

.download-button:hover {
  transform: scale(1.1); /* 호버 시 크기 확대 */
}

.modal-overlay2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); 
  z-index: 2000; 
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center; 
}

.modal2 {
  background: #ffffff; /* 흰색 배경 */
  padding: 20px; /* 내부 여백 */
  border-radius: 8px; /* 둥근 모서리 */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* 그림자 */
  width: 400px; /* 모달의 너비 */
  max-width: 90%; /* 화면 크기에 따른 최대 너비 */
  max-height: 80%; /* 화면 크기에 따른 최대 높이 */
  overflow-y: auto; /* 내용이 길 경우 스크롤 */
  font-family: "AppleSDGothicNeoM", sans-serif; /* 기본 글꼴 */
  position: relative; /* 버튼 위치 설정에 필요 */
}

.modal2 h3 {
  margin-top: 0; /* 제목과 상단 간격 제거 */
  margin-bottom: 20px;
  font-size: 24px;
  text-align: center;
  color: #333333;
  font-family: "AppleSDGothicNeoM";
}

.modal2 label {
  display: block;
  margin-bottom: 10px;
  font-size: 16px;
  color: #555555;
  font-family: "AppleSDGothicNeoM";
}

.modal2 input,
.modal2 select {
  width: calc(100% - 10px); /* 입력 필드 크기 조정 */
  padding: 8px;
  margin-top: 15px;
  margin-bottom: 15px;
  border: 1px solid #cccccc; /* 테두리 색상 */
  border-radius: 4px;
  font-size: 16px;
  font-family: "AppleSDGothicNeoM";
}

.modal-footer {
  display: flex; /* 플렉스 박스를 사용하여 정렬 */
  justify-content: flex-end; /* 오른쪽 끝으로 정렬 */
  margin-top: 20px; /* 모달 내용과 버튼 사이의 간격 */
}

.modal2 button {
  padding: 10px 20px;
  background-color: #3a6c5b; /* 버튼 배경색 */
  color: white; /* 버튼 글자색 */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  margin-right: 10px; /* 버튼 간격 */
}

.modal2 button:hover {
  background-color: #2d5d4d; /* 버튼 호버 효과 */
}

.modal2 button:last-child {
  background-color: #f44336; /* 닫기 버튼 색상 */
}

.modal2 button:last-child:hover {
  background-color: #d32f2f;
}

.checkbutton {
  display: flex;
  justify-content: space-between; /* 좌우 양 끝 정렬 */
  align-items: center;
  padding: 0;
  gap: 10px;
  margin-bottom: 10px;
}

.left-group {
  display: flex;
  align-items: center;
  font-family: 'AppleSDGothicNeoM';
  font-size: 15px;
  margin-top : 10px;
  margin-left : 5px;
}

.right-group {
  display: flex;
  gap: 10px;
}


.checkbutton button {
  background-color: #ffffff;
  border: 1px solid #007bff;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 14px;
  color: #007bff;
  cursor: pointer;
  font-size: 15px;
  font-family: 'AppleSDGothicNeoM';
  margin-top: 10px;
}

.checkbutton button:hover {
  background-color: #007bff;
  color: #ffffff;
}

.checkbutton button:disabled {
  background-color: #ccc; /* 비활성화 시 색상 */
  border-color: #ccc;
  color: #fff;
  cursor: not-allowed;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5); /* 반투명 흰색 배경 */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999; /* 최상위에 표시하여 클릭 등 이벤트 차단 */
  flex-direction: column;
}

/* 로딩 인디케이터 (스피너) 스타일 */
.loading-spinner {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
  margin-bottom: 16px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}