.title-space{
  display: flex;
  justify-content: space-between; /* 좌우로 요소 배치 */
  align-items: center;
  background-color: #2d5d4d; /* 배경 색상 */
  width: 100%;
  height: 100px;
}

.text-area {
  color: #ffffff;
  margin-left : 85px;
  font-size: 32px;
  font-family: "AppleSDGothicNeoB";
  white-space: nowrap;
}

.image-area {
  align-self: flex-start;
  margin-left: auto;
}

.side-image {
  max-width: 250px; /* 이미지 크기 조절 */
  height: auto;
}

.labeling-main {
  background-color: #ffffff;
}

.labeling {
  display: flex;
  margin-left : auto;
  margin-right : auto;
  max-width: 70vw;
  min-width: 70vw;
  height: 100%;
  min-height: 100%;
  padding: 20px;
  flex-direction: column;
  align-items: center; /* 가로 중앙 정렬 */
  box-sizing : border-box;
}

.main-box {
  border: 1px solid #000000;
  width: 100%;
  display: flex;
  height: 210px;
  min-height: 210px;
  margin-bottom: 10px;
}

.scale-box {
  display: flex;
  flex-direction: column;        
  justify-content: space-evenly;   
  align-items: center;            
  width: 70px;                     
  height: 100%;                    
  font-family: "AppleSDGothicNeoM";
  font-size: 14px;
}

.y-grid-box {
  position: relative;  
  width: 60px;
  height: 100%;
  font-family: "AppleSDGothicNeoM";
  font-size: 14px;
  margin-right: 3px;
}

.y-grid-box p {
  position: absolute;
  right: 0;
}

.p1 { top: 3%; } 
.p2 { top: 18%; }
.p3 { top: 35%; }
.p4 { top: 51%; }
.p5 { top: 69%; }
.p6 { top: 90%; }

.w-p1 { top: 3%; }  
.w-p2 { top: 43%; }
.w-p3 { top: 82%; }

.scale-button{
  flex: 1;
  width: 70px;
  color: #fff;
  border: 1px solid #ccc;
  background-color: #2d5d4d;   
  display: flex;
  justify-content: center; 
  align-items: center;    
  box-sizing: border-box;
}

.scale-button:hover{
  border : 1px solid #367357;
  background-color: #367357;
}

.scale-button:active{
  border : 1px solid #2d5d4d;
  background-color: #367357;
}

.scale-button2{
  flex: 1;
  width: 70px;
  color: #fff;
  border: 1px solid #ccc;
  background-color: #2d5d4d;   
  display: flex;
  justify-content: center; 
  align-items: center;    
  box-sizing: border-box;
}

.scale-button2:hover{
  border : 1px solid #367357;
  background-color: #367357;
}

.scale-button2:active{
  border : 1px solid #2d5d4d;
  background-color: #367357;
}

.wave-box {
  border: 1px solid #000000;
  border-radius: 3px;
  width: 100%;
  height: 180px;
  min-height: 180px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-bottom: 5px;
  margin-top: 5px;
  background-color: #FFFFFF;
  position: relative;
}

.sub-box{
  width: 100%;
  height: 100%;
}

.spectrogram-box {
  border: 1px solid #000000;
  border-radius: 3px;
  width: 100%;
  height: 180px;
  min-height: 180px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-bottom: 5px;
  margin-top : 5px;
  background-color: #FFFFFF;
  position: relative;
}

.x-grid-box {
  position: relative;  
  width: 100%;
  height: 20px;
  font-family: "AppleSDGothicNeoM";
  font-size: 14px;
  margin-right: 3px;
}

.x-grid-box p {
  position: absolute;
  right: 0;
}

.p11 { left: 17.8%; } 
.p22 { left: 37.6%; }
.p33 { left: 57.6%; }
.p44 { left: 77.7%; }

.colormap-box {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 90px; 
  height: 100%; 
  font-family: "AppleSDGothicNeoM";
  font-size: 14px;
  padding: 10px;
  padding-bottom: 13px;
  padding-right: 0px;
  padding-top: 0px;
}

.oscilloscope {
  display: block;
  max-width: 100%;
  max-height: 100%;
}

.spectrogram {
  display: block;
  max-width: 100%;
  max-height: 100%;
}

.colorbar {
  width: 100%; 
  height: 100%;  
  padding: 0;
  margin: 0;
  display: block;
}

.wave-box img {
  width: 100%;
  height: 100%; 
  border-radius: 5px;
}

.label-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  width : 100%;
  height : 70px;
  gap : 10px;
  justify-content: center;
}

.arrow-button {
  display: flex;
  cursor: pointer;
  width : 50px;
  height : 50px;
  border : 1px solid;
  border-color : black;
  border-radius: 5px;
  background-color: #ffffff;
  color : #B7B7B7;
  padding: 10px; 
  justify-content: center;
  align-items: center;
}

.arrow-button:hover {
  transform: scale(1.01); 
}

.label {
  display: flex;
  flex : 1;
  font-size: 18px; 
  font-family : "AppleSDGothicNeoM";
  text-align: center; 
  align-items: center;
  justify-content: center;
  border: 1px solid black;
  background-color: #FFFFFF;
  border-radius: 5px;
  margin : 10px 10px;
  height : 50px;
}

.checkbox-container {
  width: 100%;
  text-align: left; /* 텍스트와 체크박스를 왼쪽 정렬 */
  margin: 10px 0; /* 위아래 여백 추가 */
}

.checkbox-container label {
  font-size: 14px;
  font-family: "AppleSDGothicNeoM";
  color: #000;
  display: flex;
  align-items: center;
  gap: 10px; /* 체크박스와 텍스트 간격 */
}

.checkbox {
  width: 16px;
  height: 16px;
  border: 1px solid #ccc;
  border-radius: 3px;
  cursor: pointer;
  margin: 0;
  padding: 0;
}


.audio-player {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 10px;
}

.label-info {
  width: 100%;
  display: flex;
  font-size: 14px;
  font-family: "AppleSDGothicNeoM";
  color: red;
  text-align: left;
  margin-bottom: 20px;
  padding-left: 20px;
  white-space: nowrap;
}

.label {
  margin-right: 15px;
  font-size: 18px;
  font-family: "AppleSDGothicNeoM";
}

.question-box {
  width : 100%;
  border-radius: 5px;
  height: 100%;
  min-height: 100px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin-bottom: 30px;
  background-color: #F2F2F2;
}

.button-title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  background-color: #2d5d4d;
  color : #FFFFFF;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-size : 18px;
  font-family: 'AppleSDGothicNeoL';
}

.button-group-wrapper {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center; /* 수직 중앙 정렬 */
}

.button-group {
  width: 100%;
  display: flex;
  gap: 10px; /* 버튼 간 간격 조정 */
  padding: 20px; /* 그룹 전체에 패딩 추가 */
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap; 
}

.button-item {
  width: 120px;
  height: 40px; /* 고정된 높이 설정 (선택 사항) */
  padding: 10px 20px; /* 버튼 안의 텍스트에 대한 패딩 */
  border: none; /* 기본 테두리 제거 */
  border-radius: 25px; /* 둥근 모서리 */
  background-color: #d3d3d3; /* 기본 버튼 색상 (회색) */
  color: #000; /* 기본 텍스트 색상 */
  cursor: pointer; /* 마우스 포인터 변경 */
  transition: background-color 0.3s; /* 배경색 변경 시 부드러운 전환 효과 */
  font-size: 14px;
  font-family: 'AppleSDGothicNeoM';
  box-sizing: border-box;
  white-space: nowrap;
}

.button-item.selected {
  background-color: #2d5d4d; /* 선택된 버튼 색상 (초록색) */
  color: #fff; /* 선택된 버튼의 텍스트 색상 (흰색) */
}

.button-item:hover {
  background-color: #2d5d4d; /* 버튼 위에 마우스를 올렸을 때 색상 변화 */
}

.species-group-wrapper {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  width: 100%;
  box-sizing: border-box;
  justify-items: stretch;
  align-items: stretch;
}

.species-item {
  background-color: #F2F2F2;
  padding: 10px;
  text-align: center;
  box-sizing: border-box;
  display: flex;
  justify-content: center; /* 내부 텍스트 중앙 정렬 */
  align-items: center; /* 내부 텍스트 수직 중앙 정렬 */
  height: 100%; /* 그리드 셀의 높이를 모두 채우기 위해 100% 설정 */
  width: 100%; 
  cursor: pointer;
}

.species-item.selected {
  background-color: #2d5d4d; /* 선택된 버튼 색상 (초록색) */
  color: #fff; /* 선택된 버튼의 텍스트 색상 (흰색) */
}

.species-item:hover {
  background-color: #2d5d4d; /* 버튼 위에 마우스를 올렸을 때 색상 변화 */
}

.custom-input {
  padding: 10px;
  width: 100%;
  height : 50px;
  border: 1px solid #d3d3d3;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-sizing: border-box;
  font-family: "AppleSDGothicNeoM";
  font-size : 14px;
}

.search-results {
  width: 100%;
  background-color: #fff;       /* 흰색 배경 */
  border: 1px solid #ccc;       /* 얇은 회색 테두리 */
  border-top: none;             /* 입력 필드와 연결된 느낌을 주기 위해 상단 테두리 제거 */
  max-height: 200px;            /* 최대 높이 설정 (스크롤 발생) */
  overflow-y: auto;             /* 내용이 많으면 세로 스크롤 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 부드러운 그림자 효과 */
  font-family: "AppleSDGothicNeoM";
  font-size : 14px;
  z-index: 1000;                /* 다른 요소 위에 표시 */
}

.search-result-item {
  padding: 8px 12px;  /* 내부 여백 */
  cursor: pointer;    /* 클릭 가능한 커서 */
  transition: background-color 0.2s ease; /* 마우스 오버 시 부드러운 배경색 변화 */
}

.search-result-item:hover {
  background-color: #f0f0f0;
}

.button-box {
  display: flex;             
  justify-content: flex-end;
  align-items: center;     
  width: 100%;       
}

.submit-button {
  display: flex;
  padding: 10px 20px;  
  width : 150px;       
  height : 30px;
  background-color: #2d5d4d;  
  color: white;              
  border-radius: 5px;
  border: none;
  margin-bottom: 20px;
  font-family: 'AppleSDGothicNeoM';
  font-size : 18px;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #367357; 
  transform: scale(1.01);  
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2); 
}

.submit-button:active {
  background-color: #24523d;
  transform: scale(0.99);  
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
}

.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}