.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 502px;
  height: 86px;
  border: 2px solid #000000;
  border-radius: 10px;
  padding: 12px 24px;
  box-sizing: border-box;
  cursor: pointer;
  background-color: #1E1E1E; /* 기본 배경색 설정 */
  transition: all 0.2s ease-in-out;
}

.text-wrapper {
  color: #ffffff;
  font-family: "AppleSDGothicNeoB00-Regular", Helvetica;
  font-size: 32px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.2;
  text-align: center;
}

.button:hover {
  border: 3px solid #2c2c2c;
  background-color: #383838; /* 호버 시 배경색 추가 */
}

.button:active,
.button.active {
  background-color: #454545; /* 클릭 시 배경색 변경 */
  border-color: #1c1c1c; /* 클릭 시 테두리 색상 변경 */
  transform: scale(0.98); /* 클릭할 때 버튼이 살짝 눌리는 듯한 효과 */
}