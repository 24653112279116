.body {
  min-width: 100%;
  min-height: 100%;
  background-color: #2d5d4d; 
}

.home {
  display: flex;
  margin-left : auto;
  margin-right : auto;
  max-width: 100vw;
  min-width: 100%;
  overflow-x: hidden;
  padding: 20px;
  flex-direction: column;
  background-color: #2d5d4d;
}

.container {
  position: relative;
  border: 1.5px solid white;
  padding: 20px;
  border-radius: 20px;
  margin-top: 30px;
  box-sizing: border-box;
  min-height: 80vh;
  background-color: #2d5d4d;
}

.container-label {
  position: absolute;
  top: -10px; /* 라벨의 위치를 조정 */
  left: 10px; /* 왼쪽 여백 */
  background-color: #2d5d4d; /* 배경색 (컨테이너와 동일) */
  padding: 0 10px; /* 텍스트 주변 여백 */
  font-size: 20px; /* 텍스트 크기 */
  font-family: 'AppleSDGothicNeoB';
  color:#FFFFFF;
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height : 100%;
  padding: 20px;
  gap: 20px;
}

.chart-box-pie {
  max-width: 45%;
  min-width: 30%;
  height: 70vh; 
  padding: 10px 10px 40px;
}

.details {
  width : 55%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap : 80px;
  margin-right: 50px;
}

.data-row {
  display: flex;
  align-items: center;
  justify-content: space-between; /* 좌우 정렬 */
  position: relative; /* 점선을 위한 기준 */
  width: calc(100% - 20px);;
}

/* 왼쪽 라벨 */
.label-left {
  text-align: left;
  white-space: nowrap; /* 텍스트 줄바꿈 방지 */
  flex-shrink: 0; /* 공간 부족 시 크기 줄어들지 않음 */
  font-size: 18px;
  color: white;
  font-family: 'AppleSDGothicNeoM';
}

/* 오른쪽 라벨 */
.label-right {
  text-align: right;
  white-space: nowrap;
  flex-shrink: 0;
  font-size: 20px;
  color: white;
  font-family: 'AppleSDGothicNeoB';
}

.dots {
  flex-grow: 1; /* 남은 공간을 채움 */
  border-bottom: 1.5px dotted white; /* 점선 스타일 */
  height: 1px; /* 점선 높이 */
  margin: 0 10px; /* 좌우 간격 */
}

.container-bottom {
  position: relative;
  border: 1.5px solid white;
  padding: 20px;
  border-radius: 20px;
  margin-top: 150px;
  box-sizing: border-box;
  min-height: 40vh;
  background-color: #2d5d4d;
}

.details-bottom {
  width : 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 100px;
  margin-right: 100px;
  gap : 80px;
}

.header-row {
  display: flex;
  width: 100%;
  justify-content: space-between; /* 좌우 정렬 */
  align-items: center;
}

.header {
  width: 120px;
  background-color: white; /* 헤더 배경색 */
  color: black; /* 텍스트 색상 */
  padding: 15px 20px; /* 내부 여백 */
  border-radius: 25px; /* 둥근 모서리 */
  font-family: 'AppleSDGothicNeoB';
  text-align: center;
}

.content-bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height : 100%;
  padding: 20px;
}

.data-row-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between; /* 좌우 정렬 */
  position: relative; /* 점선을 위한 기준 */
  width: 100%;
  padding-left: 35px;
  padding-right: 20px;
}


