.download {
  display: flex;
  margin-left : auto;
  margin-right : auto;
  max-width: 70vw;
  min-width: 70vw;
  padding: 20px;
  flex-direction: column;
  min-height: 100vh;
  box-sizing : border-box;
}

.search-options {
  position: relative;
  display: flex;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-top : 20px;
  padding-left: 10px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between
}

.toggle-button {
  display: flex;
  background-color: #ffffff;
  border: none;
  border-radius: 5px;
  padding: 5px 20px;
  font-size: 16px;
  cursor: pointer;
  align-items: center;
}

.options-container {
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.option-group {
  margin-bottom: 20px;
}

.option-group h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.option-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.option-button {
  background-color: #f5f5f5;
  border: 1px solid #cccccc;
  border-radius: 20px;
  padding: 5px 15px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.option-button:hover {
  background-color: #e6e6e6;
}

.option-button.selected {
  background-color: #007bff;
  color: #ffffff;
}

.options-footer {
  display: flex;
  justify-content: flex-end;
}

.seach-button{
  background-color: #ffffff;
  border: 1px solid #007bff;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 14px;
  color: #007bff;
  cursor: pointer;
  margin-right: 10px;
}

.seach-button:hover{
  background-color: #007bff;
  color: #ffffff;
}

.reset-button {
  background-color: #ffffff;
  border: 1px solid #007bff;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 14px;
  color: #007bff;
  cursor: pointer;
}

.reset-button:hover {
  background-color: #007bff;
  color: #ffffff;
}

.search-result {
  margin-top: 20px; /* options-container와의 간격 */
  background-color: #ffffff;
  border: 1px solid #cccccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 10px;
  overflow-x: auto;
}

.result-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 16px;
  text-align: center;
  font-family: "AppleSDGothicNeoM";
}

.result-table th,
.result-table td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px;
  border: 1px solid #dddddd;
}

.result-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.result-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.loading-icon {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* 페이지네이션 스타일 */
.pagination {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.page-button {
  margin: 0 5px;
  padding: 8px 12px;
  border: 1px solid #007bff;
  background-color: #ffffff;
  color: #007bff;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
}

.page-button:hover {
  background-color: #007bff;
  color: #ffffff;
}

.page-button.active {
  background-color: #007bff;
  color: #ffffff;
  font-weight: bold;
}

.download-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 1px;
  transition: transform 0.2s;
}

.download-button:hover {
  transform: scale(1.1); /* 호버 시 크기 확대 */
}