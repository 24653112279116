.title-space{
  display: flex;
  justify-content: space-between; /* 좌우로 요소 배치 */
  align-items: center;
  background-color: #2d5d4d; /* 배경 색상 */
  width: 100%;
  height: 100px;
}

.text-area {
  color: #ffffff;
  margin-left : 85px;
  font-size: 32px;
  font-family: "AppleSDGothicNeoB";
  white-space: nowrap;
}

.image-area {
  align-self: flex-start;
  margin-left: auto;
}

.side-image {
  max-width: 250px; /* 이미지 크기 조절 */
  height: auto;
}

.upload-main {
  background-color: #ECECEB;
}

.upload {
  display: flex;
  margin-left : auto;
  margin-right : auto;
  max-width: 80vw;
  height: 100vh;
  padding: 20px;
  flex-direction: column;
  align-items: center; /* 가로 중앙 정렬 */
  min-height: 100vh;
  box-sizing : border-box;
  overflow: hidden;
}

.dropzone {
  display: flex; 
  border: 2px dashed #2d5d4d;
  border-radius: 5px;
  padding: 20px;
  cursor : pointer;
  margin : 20px auto;
  width: 100%;
  height : 250px;
  justify-content: center;
  align-items: center;
  background-color: #FFFFFF;
  flex-direction: column;
  gap : 20px;
}

.drag-search {
  display: flex;
  background-color: #2d5d4d;
  width : 180px;
  height: 50px;
  border-radius: 20px;
  align-items: center;
  justify-content: flex-start;
  padding-left: 18px;
  color: #FFFFFF;
  font-family: 'AppleSDGothicNeoM';
  font-size: 18px;
  gap : 10px;
}

.left-align {
  max-height: 100px;
  overflow-y: auto;
  border: 1px solid #ddd; /* 경계선 추가 (선택) */
  padding: 10px; /* 패딩 추가 */
  border-radius: 5px; /* 둥근 모서리 */
  width: 100%; /* 부모 컨테이너의 너비를 차지하게 설정 */
  display: flex;
  justify-content: flex-start; /* 내부 요소를 좌측 정렬 */
}

.file-li {
  font-family: 'AppleSDGothicNeoM';
  margin-bottom: 5px;
  display: flex;
}

.button-sytle {
  all: unset;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 12px 24px;
  margin-top: 20px;
  width: 100%;
  height: 65px;
  background-color: #2d5d4d;
  border-radius: 10px;
  cursor: pointer;
  white-space: nowrap;
  font-size: 24px;
  font-family: "AppleSDGothicNeoB", Helvetica;
  color : #FFFFFF;
  transition: background-color 0.3s, transform 0.2s;
}

/* 호버링 효과 */
.button-sytle:hover {
  background-color: #367358; /* 마우스를 올렸을 때 조금 더 밝은 색상 */
  transform: scale(1.005); /* 버튼을 살짝 확대하여 호버 효과를 강조 */
}

/* 클릭 효과 */
.button-sytle:active {
  background-color: #244235; /* 클릭 시 더 어두운 색상 */
  transform: scale(0.995); /* 버튼을 살짝 줄여서 클릭된 느낌을 줌 */
}

.upload-status {
  margin-top: 20px;
  font-size: 16px;
  font-family: "AppleSDGothicNeoM";
  color : red;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; 
}

.modal-content {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  width: 90%; 
  max-width: 400px; 
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  box-sizing: border-box; 
}

.modal-content h3 {
  margin-bottom: 15px;
  font-size: 14px;
  color: #333;
  font-family: 'AppleSDGothicNeoM';
}

.text-area2 {
  width: 100%;
  height: 40px;
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-sizing: border-box; 
  resize: none; 
  font-size: 14px;
  overflow: hidden; 
  font-family: 'AppleSDGothicNeoM';
}

.modal-buttons {
  display: flex;
  justify-content: space-between;
  gap: 10px; 
}

.save-button,
.cancel-button {
  flex: 1;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  box-sizing: border-box; /* 패딩 포함 크기 조정 */
  font-family: 'AppleSDGothicNeoM';
}

.save-button {
  background-color: #2d5d4d;
  color: white;
  transition: background-color 0.3s, transform 0.2s;
}

.save-button:hover {
  background-color: #367358; /* 마우스를 올렸을 때 조금 더 밝은 색상 */
  transform: scale(1.005); /* 버튼을 살짝 확대하여 호버 효과를 강조 */
}

.cancel-button {
  background-color: #f44336;
  color: white;
  transition: background-color 0.3s, transform 0.2s;
}

.cancel-button:hover {
  background-color: #ff5f52; /* 마우스를 올렸을 때 조금 더 밝은 색상 */
  transform: scale(1.005);
}

.file-list-wrapper {
  display: flex;
  justify-content: center;
  gap: 20px;  /* 두 개의 목록 사이 간격 */
  margin-top: 20px;
}

.file-list-container {
  width: 300px;  /* 각 박스의 너비 */
  padding: 15px;
  max-height: 300px;
  overflow-y: auto;
  border-radius: 8px;
  font-size: 14px;
  font-family: "AppleSDGothicNeoM", sans-serif;
  line-height: 1.6;
  text-align: center;
}

/* 성공 및 실패 목록 제목 스타일 */
.file-list-container h3 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

/* 리스트 아이템 정리 */
.file-list-container ul {
  list-style-type: none;
  padding-left: 0;
}

.file-list-container li {
  padding: 8px 12px;
  border-bottom: 1px solid #ddd;
}

.file-list-container li:last-child {
  border-bottom: none;
}

/* ✅ 성공 파일 스타일 */
.file-list-container.success {
  background-color: #e6f7e6;
  border: 1px solid #a3d9a5;
  color: #276749;
}

/* ❌ 실패 파일 스타일 */
.file-list-container.fail {
  background-color: #ffe6e6;
  border: 1px solid #d9a3a3;
  color: #742a2a;
}

.upload-progress-wrapper {
  width: 100%;
  margin-top: 20px;
  font-family: "AppleSDGothicNeoB", Helvetica;
  text-align: center;
}

/* 프로그레스 바 기본 스타일 */
.upload-progress-wrapper progress {
  width: 100%;
  height: 20px;
  border-radius: 5px;
  overflow: hidden;
  appearance: none;
  background-color: #e0e0e0; /* 배경 색상 */
  border: none;
  position: relative;
}

.upload-progress-text {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  font-weight: bold;
  color: white;
  z-index: 2;
}

/* 크롬, 사파리 스타일 */
.upload-progress-wrapper progress::-webkit-progress-bar {
  background-color: #e0e0e0;
  border-radius: 5px;
}

/* ✅ 초록 → 연두 그라디언트 적용 */
.upload-progress-wrapper progress::-webkit-progress-value {
  background: linear-gradient(to right, #2dbd6e, #a6f77b); /* 초록 → 연두 */
  border-radius: 5px;
  transition: width 0.4s ease;
}

/* 파이어폭스용 */
.upload-progress-wrapper progress::-moz-progress-bar {
  background: linear-gradient(to right, #2dbd6e, #a6f77b);
  border-radius: 5px;
}

.upload-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}
