.dashboard-layout {
  height: 100vh;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0;
}

.main-body {
  flex: 1;
  padding-top: 81px;
  background-color: #f9f9f9;
}

@media (max-width : 990px) {
  .main-body {
    padding-top: 58px;
  }
}

@media ((min-width: 990px) and (max-width : 1180px)) {
  .main-body {
    padding-top: 70px;
  }
}

.footer {
  display: flex;
  background-color: #ECECEB; /* 푸터 배경색 */
  padding: 50px 50px; /* 상하 여백 */
  text-align: center; /* 텍스트 가운데 정렬 */
  flex-direction: column;
  font-size: 12px; /* 글씨 크기 */
  color: #666666; /* 글씨 색상 */
  gap : 15px;
}

.footer button {
  color: #ffffff; /* 링크 색상 */
  margin: 0 5px; /*링크 간격*/
  border: none;
  cursor: pointer;
}
