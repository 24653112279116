.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5); 
    z-index: 2000; 
    overflow-y: auto;
    display: flex;
    justify-content: center;
    align-items: flex-start; 
    padding-top: 20px; 
    padding-bottom: 20px; 
}

.modal {
    position: relative;
    background: white;
    margin-top: 60px;
    margin-bottom: 60px;
    width: 60%;
    max-width: 600px;
    padding: 25px;
    border-radius: 5px;
    z-index: 2001;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.modal-content {
    position: relative;
    background: white;
    padding: 20px;
    width: 80%;
    max-width: 800px;
    margin-top: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.modal-close {
    position: absolute;
    top: 10px;
    right: 15px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}

.modal-body {
    margin-top: 15px;
    font-size: 1rem;
    line-height: 1.5;
}

.modal-title {
    font-family: "AppleSDGothicNeoM";
    color : #000;
    font-size: 18px;
    text-align: start;
}

.sub-title {
    font-family: "AppleSDGothicNeoL";
    color : #000;
    font-size: 16px;
    text-align: start;
    margin-top: 50px;
    margin-bottom: 40px;
}   

.sub-title2 {
    font-family: "AppleSDGothicNeoM";
    color : #000;
    font-size: 16px;
    text-align: start;
    margin-top: 50px;
}   

.sub-contents {
    font-family: "AppleSDGothicNeoL";
    color : #000;
    font-size: 16px;
    text-align: start;
    line-height: 1.5;
}

.sub-contents2 {
    font-family: "AppleSDGothicNeoL";
    color : #000;
    font-size: 16px;
    text-align: start;
    line-height: 1.5;
    margin-top: 25px;
}

.sub-contents3 {
    font-family: "AppleSDGothicNeoL";
    color : #000;
    font-size: 16px;
    text-align: start;
    line-height: 1.5;
}