@font-face {
    font-family: 'AppleSDGothicNeoB';
    src: url('assets/font/AppleSDGothicNeoB.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'AppleSDGothicNeoEB';
    src: url('assets/font/AppleSDGothicNeoEB.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'AppleSDGothicNeoH';
    src: url('assets/font/AppleSDGothicNeoH.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'AppleSDGothicNeoL';
    src: url('assets/font/AppleSDGothicNeoL.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'AppleSDGothicNeoM';
    src: url('assets/font/AppleSDGothicNeoM.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
