.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #2d5d4d;
  min-height: 100vh;
  
}

.group {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 700px; /* 최대 너비 */
}

.title {
  color: white;
  font-family: "AppleSDGothicNeoB00";
  font-size: 40px;
  text-align: center;
}

.image {
  width: 100%;
  max-width: 700px;
  height: auto;
  object-fit: cover;
  margin-bottom: 20px;
}

.id-input,
.pw-input {
  width: 100%;
  max-width: 350px;
  height: 50px;
  padding: 0 15px;
  font-size: 16px;
  border: 2px solid #c5c5c5;
  border-radius: 10px;
  background-color: #f9f9f9;
  color: #000000;
  box-sizing: border-box;
  margin-bottom: 20px;
  outline: none;
}

.id-input:focus,
.pw-input:focus {
  border: 2px solid #000000; /* 검은색 테두리로 변경 */
}

.login-button {
  width: 100%;
  max-width: 350px;
  height: 50px;
  background-color: #1A322A;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.login-button:hover {
  border: none;
  background-color: #11261E; /* 호버 시 색상 변경 */
}

.design-component-instance-node {
  font-size: 18px;
  font-family: "AppleSDGothicNeoB";
  color: #ffffff;
}

